import required from "vuelidate/lib/validators/required";

export function contactForm(item = null) {
    return {
        id: item ? item.id : null,
        email: item ? item.email : '',
        phone: item ? item.phone : '',
        phone_code_id: item ? item.phone_code_id : 118,
        fax: item ? item.fax : '',
        mobile: item ? item.mobile : '',
        mobile_code_id: item ? item.mobile_code_id : 118,
        website: item ? item.website : '',
        postal_code: item ? item.postal_code : '',
        address_url: item ? item.address_url : '',
        address: item ? item.address : '',
        company_id: item ? item.company_id : '',
        company_address: item ? item.company_address : ''
    };
}

export function contactFormValidation() {
    return {
        email: {
            required
        },
        mobile: {
            required
        },
        company_address: {
            required
        },
        address: {
            required
        },
        fax: {
            required
        },
        company_id: { required }
    }
}

export function serviceOptionsForm() {
    return {
        serviceOptions: [],
        company_id: '',
    };
}

export function serviceOptionsFormValidation() {
    return {

        company_id: {
            required
        },
        serviceOptions: {
            required
        }

    };
}

export function serviceForm() {
    return {
        services: [],
        company_id: '',
    };
}

export function serviceFormValidation() {
    return {

        company_id: {
            required
        },
        services: {
            required
        }

    };
}

export function requisiteForm(item) {
    return {
        id: item ? item.id : '',
        company_id: item ? item.company_id : '',
        inn: item ? item.inn : '',
        bank_name: item ? item.bank_name : '',
        bik: item ? item.bik : '',
        swift: item ? item.swift : '',
        multi: item ? item.multi : '',
        KGS: item ? item.KGS : '',
        USD: item ? item.USD : '',
        EUR: item ? item.EUR : '',
        RUB: item ? item.RUB : '',
        ogrn: item ? item.ogrn : '',
        okpo: item ? item.okpo : '',
        bank_korrespondent: item ? item.bank_korrespondent : '',
        bank_posrednik: item ? item.bank_posrednik : '',
    }
}

export function requisiteFormValidation() {
    return {
        inn: { required },
        bank_name: { required },
        bik: { required },
        swift: { required },
        USD: { required }
    }
}