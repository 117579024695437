<template>
  <div id="company-view" class="page-layout__inner--column">
    <div class="row mb-3">
      <div class="col-md-6 col-sm-12 mb-3">
        <section class="section">
          <div class="section-header">
            <span class="section__title">
              {{ getTranslation('generalInfo') }}
            </span>
            <span v-if="company.coupon_id" class="badge badge-success float-right">
              <CIcon name="cil-star"/>{{ company.coupon.code }}
            </span>

          </div>
          <div class="section__body">
            <div class="section__group">
              <table class="table-doc">
                <tbody>
                <tr>
                  <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('title') }}:</th>
                  <td class="table-doc__cell">{{ company.title }}</td>
                </tr>
                <tr v-if="company.country">
                  <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('country') }}:</th>
                  <td class="table-doc__cell">{{ company.country.fullText }}</td>
                </tr>
                <tr v-if="company.city">
                  <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('city') }}:</th>
                  <td class="table-doc__cell">{{ company.city.fullText }}</td>
                </tr>
                <tr v-if="company.airport">
                  <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('nearestAirport') }}:</th>
                  <td class="table-doc__cell">{{ company.airport.fullText }}</td>
                </tr>
                <tr>
                  <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('status') }}:</th>
                  <td class="table-doc__cell">
                   <span>
                    {{ company.active ? getTranslation('active') : getTranslation('blocked') }}
                  </span>
                  </td>
                </tr>
                <tr>
                  <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('description') }}:</th>
                  <td class="table-doc__cell">{{ company.description }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
      <div class="col-md-6 col-sm-12">

        <ul class="nav nav-pills nav-tabs-boxed">
          <li v-if="hasPermission('company.get')" class="nav-item">
            <a v-if="company.contact" class="nav-link" :class="{'active':tabs.contact}" @click="tabSelect('contact')" href="javascript:void(0)">{{ getTranslation('contacts') }}</a>
          </li>
          <li v-if="hasPermission('company.profile.requisite')" class="nav-item">
            <a class="nav-link" :class="{'active':tabs.requisite}" @click="tabSelect('requisite')" href="javascript:void(0)">{{ getTranslation('requisites') }}</a>
          </li>
          <li v-if="hasPermission('company.profile.documents')" class="nav-item">
            <a class="nav-link" :class="{'active':tabs.document}" @click="tabSelect('document')" href="javascript:void(0)">{{ getTranslation('documents') }}</a>
          </li>
          <li v-if="hasPermission('company.profile.services')" class="nav-item">
            <a class="nav-link" :class="{'active':tabs.service}" @click="tabSelect('service')" href="javascript:void(0)">{{ getTranslation('serviceCategory') }}</a>
          </li>
          <li v-if="hasPermission('company.profile.serviceOptions')" class="nav-item">
            <a class="nav-link" :class="{'active':tabs.serviceOption}" @click="tabSelect('serviceOption')" href="javascript:void(0)">{{ getTranslation('serviceOptions') }}</a>
          </li>
        </ul>

        <div class="tab-content">
          <div v-if="hasPermission('company.get')" class="tab-pane fade" :class="{'show active':tabs.contact}">
            <section class="section">
              <div class="section-header">
                <div class="section__controls">
                  <div class="section__controls-group">
                    <button v-if="company.status === 'NEW' && hasPermission('portal.company.updateContact')" type="button" @click="openContactForm" class="btn btn-info btn-sm">
                      {{ getTranslation('edit') }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="section__body">
                <div class="section__group">
                  <table v-if="company.id && company.contact" class="table-doc">
                    <tbody>
                    <tr>
                      <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('email') }}:</th>
                      <td class="table-doc__cell">{{ company.contact.email }}</td>
                    </tr>
                    <tr>
                      <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('mobile') }}:</th>
                      <td class="table-doc__cell">
                        <span v-if="company.contact.id && company.contact.mobileCode">{{ company.contact.mobileCode.code }}</span>
                        {{ company.contact.mobile }}
                      </td>
                    </tr>
                    <tr>
                      <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('phone') }}:</th>
                      <td class="table-doc__cell">
                        <span v-if="company.contact.phone && company.contact.phoneCode">{{ company.contact.phoneCode.code }}  {{ company.contact.phone }} </span>
                      </td>
                    </tr>
                    <tr>
                      <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('fax') }}:</th>
                      <td class="table-doc__cell">{{ company.contact.fax }}</td>
                    </tr>
                    <tr>
                      <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('postalCode') }}:</th>
                      <td class="table-doc__cell">{{ company.contact.postal_code }}</td>
                    </tr>
                    <tr v-if="company.contact.website">
                      <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('website') }}:</th>
                      <td class="table-doc__cell">{{ company.contact.website }}</td>
                    </tr>
                    <tr v-if="company.contact.address">
                      <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('address') }}:</th>
                      <td class="table-doc__cell">{{ company.contact.address }}</td>
                    </tr>
                    <tr v-if="company.contact.company_address">
                      <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('companyAddress') }}:</th>
                      <td class="table-doc__cell">{{ company.contact.company_address }}</td>
                    </tr>
                    </tbody>
                  </table>
                  <span v-if="!company.contact" class="empty">{{ getTranslation('notAddedYet') }}</span>
                </div>
              </div>
            </section>
          </div>
          <div v-if="hasPermission('company.profile.requisite')" class="tab-pane fade" :class="{'show active':tabs.requisite}">
            <section class="section">
              <div class="section__body">
                <div class="alert alert-danger" v-if="$store.getters['user/getUser'].isAgent && !requisite.id" role="alert">
                  {{ getTranslation('agent_need_to_fill_requisite') }}
                </div>
                <div class="section-header">
                  <div class="section__controls">
                    <div class="section__controls-group">
                      <button type="button" v-if="company.status === 'NEW' && hasPermission('portal.company.addBankRequisites')" @click="openRequisiteForm" class="btn btn-info btn-sm">
                        {{ getTranslation(requisite.id ? 'edit' : 'add') }}
                      </button>
                    </div>
                  </div>
                </div>

                <div class="section__group">
                  <table v-if="requisite.id" class="table-doc">
                    <tbody>
                    <tr>
                      <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('bankName') }}:</th>
                      <td class="table-doc__cell">{{ requisite.bank_name }}</td>
                    </tr>
                    <tr>
                      <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('inn') }}:</th>
                      <td class="table-doc__cell">{{ requisite.inn }}</td>
                    </tr>
                    <tr>
                      <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('bik') }}:</th>
                      <td class="table-doc__cell">{{ requisite.bik }}</td>
                    </tr>
                    <tr>
                      <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('swift') }}:</th>
                      <td class="table-doc__cell">{{ requisite.swift }}</td>
                    </tr>
                    <tr>
                      <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('multi') }}:</th>
                      <td class="table-doc__cell">{{ requisite.multi }}</td>
                    </tr>
                    <tr>
                      <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('KGS') }}:</th>
                      <td class="table-doc__cell">{{ requisite.KGS }}</td>
                    </tr>
                    <tr>
                      <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('USD') }}:</th>
                      <td class="table-doc__cell">{{ requisite.USD }}</td>
                    </tr>
                    <tr>
                      <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('RUB') }}:</th>
                      <td class="table-doc__cell">{{ requisite.RUB }}</td>
                    </tr>
                    <tr>
                      <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('EUR') }}:</th>
                      <td class="table-doc__cell">{{ requisite.EUR }}</td>
                    </tr>
                    </tbody>
                  </table>
                  <span v-if="!requisite.id" class="empty">{{ getTranslation('notAddedYet') }}</span>
                </div>
              </div>
            </section>
          </div>
          <div v-if="hasPermission('company.profile.documents')" class="tab-pane fade" :class="{'show active':tabs.document}">
            <section class="section">
              <div class="section-header">
                <div class="section__controls">
                  <div class="section__controls-group">
                    <button v-if="company.status === 'NEW' && hasPermission('portal.company.addDocument')" type="button" @click="openDocumentModal" class="btn btn-info btn-sm">{{ getTranslation('uploadDocument') }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="section__body">

                <div class="alert alert-danger" v-if="$store.getters['user/getUser'].isAgent && documents.length < 1" role="alert">
                  {{ getTranslation('agent_need_to_add_documents') }}
                </div>

                <div class="section__group">
                  <DocumentsComponent :download-url="this.$urls.company.documents.url.replace(':id',this.$route.params.id)"
                                      :add-classes="'col-md-3 col-sm-4'" :title-class="'font-sm'" :description-class="'font-sm'"
                                      :documents="documents"/>
                  <span v-if="documents.length<1" class="empty">{{ getTranslation('notAddedYet') }}</span>
                </div>
              </div>
            </section>
          </div>
          <div v-if="hasPermission('company.profile.services')" class="tab-pane fade" :class="{'show active':tabs.service}">
            <section class="section">
              <div class="section-header">
                <div class="section__controls">
                  <div class="section__controls-group">
                    <button v-if="company.status === 'NEW' && hasPermission('portal.company.addServices')" type="button" @click="openServiceForm" class="btn btn-info btn-sm">
                      {{ getTranslation(services.length > 0 ? 'edit' : 'add') }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="section__body">

                <div class="alert alert-danger" v-if="$store.getters['user/getUser'].isAgent && services.length < 1" role="alert">
                  {{ getTranslation('agent_need_to_add_services') }}
                </div>

                <div class="section__group">
                  <ul v-if="services.length > 0" class="list-group">
                    <li v-for="service in services" v-bind:key="service.id" class="list-group-item">
                      {{ service.title }}
                    </li>
                  </ul>
                  <span v-if="services.length < 1" class="empty">{{ getTranslation('notAddedYet') }}</span>
                </div>
              </div>
            </section>
          </div>
          <div v-if="hasPermission('company.profile.serviceOptions')" class="tab-pane fade" :class="{'show active':tabs.serviceOption}">
            <section class="section">
              <div class="section-header">
                <div class="section__controls">
                  <div class="section__controls-group">
                    <button v-if="company.status === 'NEW' && hasPermission('portal.company.addServiceOptions')" type="button" @click="openServiceOptionsForm" class="btn btn-info btn-sm">
                      {{ getTranslation(serviceOptions.length > 0 ? 'edit' : 'add') }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="section__body">

                <div class="alert alert-danger" v-if="$store.getters['user/getUser'].isAgent && serviceOptions.length < 1" role="alert">
                  {{ getTranslation('agent_need_to_add_serviceOptions') }}
                </div>

                <div class="section__group">
                  <ul v-if="serviceOptions.length > 0" class="list-group">
                    <li v-for="service in serviceOptions" v-bind:key="service.id" class="list-group-item">
                      {{ service.airText }}
                    </li>
                  </ul>
                  <span v-if="serviceOptions.length < 1" class="empty">{{ getTranslation('notAddedYet') }}</span>
                </div>
              </div>
            </section>
          </div>
        </div>

      </div>
    </div>
    <!--   contact update  -->
    <modal
        :title="this.getTranslation('edit')"
        :show.sync="modals.contact"
        :centered="false"
        :ok-button-label="this.getTranslation('save')"
        @clickOK="updateContact"
        @modalCancel="resetContactForm"
    >

      <div class="form-group">
        <label class="form__label--block form__label">
          {{ getTranslation('email') }} <span class="required">*</span>
        </label>
        <input type="email" class="field" v-model.trim="contactForm.email" :class="{'is-invalid':$v.contactForm.email.$error}"
               :placeholder="getTranslation('email')">
        <div v-if="$v.contactForm.email.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
      </div>
      <div class="form-group">
        <label class="form__label--block form__label">
          {{ getTranslation('mobile') }} <span class="required">*</span>
        </label>
        <div v-if="showPhonCodes" class="input-group">

          <PhoneCode add-class="input-group-prepend" required :edit-item-id="contactForm.mobile_code_id" v-model="contactForm.mobile_code_id" :items="phoneCodes"/>

          <input type="text" class="field form-control" v-model.trim="contactForm.mobile" :class="{'is-invalid':$v.contactForm.mobile.$error}"
                 :placeholder="getTranslation('mobile')">
        </div>

        <div v-if="$v.contactForm.mobile.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
      </div>
      <div class="form-group">
        <label class="form__label--block form__label">
          {{ getTranslation('fax') }} <span class="required">*</span>
        </label>
        <input type="text" class="field" v-model.trim="contactForm.fax" :class="{'is-invalid':$v.contactForm.fax.$error}"
               :placeholder="getTranslation('fax')">
        <div v-if="$v.contactForm.fax.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
      </div>
      <div class="form__group">
        <label class="form__label   form__label--block" for="address">{{ getTranslation('address') }}<span class="required">*</span></label>
        <textarea v-model.trim="contactForm.address" :class="{'is-invalid':$v.contactForm.address.$error}" class="field  field--full-width  field--short-area" id="address" cols="30" rows="10"/>
        <div v-if="$v.contactForm.address.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
      </div>
      <div class="form__group">
        <label class="form__label   form__label--block" for="company_address">{{ getTranslation('legalAddress') }}<span class="required">*</span></label>
        <textarea v-model.trim="contactForm.company_address" :class="{'is-invalid':$v.contactForm.company_address.$error}" class="field  field--full-width  field--short-area" id="company_address" cols="30"
                  rows="10"/>
        <div v-if="$v.contactForm.company_address.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
      </div>

    </modal>
    <!--   service options  -->
    <modal
        :title="this.getTranslation(serviceOptions.length > 0 ? 'edit':'add')"
        :show.sync="modals.serviceOption"
        :centered="false"
        size="lg"
        :ok-button-label="this.getTranslation('save')"
        @clickOK="saveServiceOptions"
        @modalCancel="resetServiceOptionsForm"
    >
      <div class="form-group">
        <label class="form__label--block form__label">{{ getTranslation('serviceOptions') }} <span class="required">*</span>
        </label>
        <VSelect
            :options="formOptions.serviceOptions"
            :reduce="option=>option.id"
            v-model="serviceOptionsForm.serviceOptions"
            :multiple="true"
            label="full_text"
            :class="{ 'is-invalid': $v.serviceOptionsForm.serviceOptions.$error }"
            :placeholder="getTranslation('selectFromList')"
        />
        <div v-if="$v.serviceOptionsForm.serviceOptions.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
      </div>
    </modal>
    <!--   services  -->
    <modal
        :title="this.getTranslation(services.length > 0 ? 'edit':'add')"
        :show.sync="modals.service"
        :centered="false"
        size="lg"
        :ok-button-label="this.getTranslation('save')"
        @clickOK="saveServices"
        @modalCancel="resetServiceForm"
    >
      <div class="form-group">
        <label class="form__label--block form__label">{{ getTranslation('serviceCategory') }} <span class="required">*</span>
        </label>
        <VSelect
            :options="formOptions.services"
            :reduce="option=>option.id"
            v-model="serviceForm.services"
            :multiple="true"
            label="title"
            :class="{ 'is-invalid': $v.serviceForm.services.$error }"
            :placeholder="getTranslation('selectFromList')"
        />
        <div v-if="$v.serviceForm.services.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
      </div>
    </modal>

    <!--   requisite  -->
    <modal
        :title="this.getTranslation(requisiteForm.id ? 'edit':'add')"
        :show.sync="modals.requisite"
        :centered="false"
        size="lg"
        :ok-button-label="this.getTranslation('save')"
        @clickOK="saveRequisite"
        @modalCancel="resetRequsiteForm"
    >

      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="form-group">
            <label class="form__label--block form__label">{{ getTranslation('bankName') }} <span class="required">*</span>
            </label>
            <input type="text" class="form-control" v-model.trim="requisiteForm.bank_name" :class="{'is-invalid':$v.requisiteForm.bank_name.$error}"
                   :placeholder="getTranslation('bankName')">
            <div v-if="$v.requisiteForm.bank_name.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
          </div>
          <div class="form-group">
            <label class="form__label--block form__label">{{ getTranslation('inn') }}<span class="required">*</span>
            </label>
            <input type="text" class="form-control"
                   :class="{'is-invalid':$v.requisiteForm.inn.$error}"
                   v-model.trim="requisiteForm.inn" :placeholder="getTranslation('inn')">
            <div v-if="$v.requisiteForm.inn.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
          </div>
          <div class="form-group">
            <label class="form__label--block form__label">{{ getTranslation('bik') }}<span class="required">*</span>
            </label>
            <input type="text" class="form-control"
                   :class="{'is-invalid':$v.requisiteForm.bik.$error}"
                   v-model.trim="requisiteForm.bik" :placeholder="getTranslation('bik')">
            <div v-if="$v.requisiteForm.bik.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
          </div>
          <div class="form-group">
            <label class="form__label--block form__label">{{ getTranslation('swift') }} <span class="required">*</span>
            </label>
            <input type="text" class="form-control" v-model.trim="requisiteForm.swift" :class="{'is-invalid':$v.requisiteForm.swift.$error}"
                   :placeholder="getTranslation('swift')">
            <div v-if="$v.requisiteForm.swift.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
          </div>
          <div class="form-group">
            <label class="form__label--block form__label">{{ getTranslation('multiCurrency') }}</label>
            <input type="text" class="form-control" v-model.trim="requisiteForm.multi" :placeholder="getTranslation('multiCurrency')">
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="form-group">
            <label class="form__label--block form__label">{{ getTranslation('USD') }} <span class="required">*</span>
            </label>
            <input type="text" class="form-control" v-model.trim="requisiteForm.USD" :class="{'is-invalid':$v.requisiteForm.USD.$error}"
                   :placeholder="getTranslation('USD')">
            <div v-if="$v.requisiteForm.USD.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
          </div>
          <div class="form-group">
            <label class="form__label--block form__label">{{ getTranslation('KGS') }}
            </label>
            <input type="text" class="form-control" v-model.trim="requisiteForm.KGS" :placeholder="getTranslation('KGS')">
          </div>
          <div class="form-group">
            <label class="form__label--block form__label">{{ getTranslation('RUB') }}
            </label>
            <input type="text" class="form-control" v-model.trim="requisiteForm.RUB" :placeholder="getTranslation('RUB')">
          </div>
          <div class="form-group">
            <label class="form__label--block form__label">{{ getTranslation('EUR') }}
            </label>
            <input type="text" class="form-control" v-model.trim="requisiteForm.EUR" :placeholder="getTranslation('EUR')">
          </div>
        </div>
      </div>

    </modal>
    <modal
        :title="this.getTranslation('uploadDocument')"
        :show.sync="showModal"
        :centered="false"
        :ok-button-label="getTranslation('save')"
        @clickOK="uploadDocument"
        @modalCancel="reset"
    >
      <form @submit.prevent="()=>{}" method="post" enctype="multipart/form-data" class="w-100">
        <div class="form-group">
          <label class="form__label--block form__label">{{ getTranslation('document') }} <span class="required">*</span>
          </label>
          <VSelect
              :options="formOptions.documentTypes"
              :reduce="option=>option.id"
              v-model="document.type_id"
              label="title"
              :class="{ 'is-invalid': $v.document.type_id.$error }"
              :placeholder="getTranslation('selectFromList')"
          />
          <div v-if="$v.document.type_id.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
        </div>
        <div class="form__group">
          <label class="form__label--block form__label">{{ getTranslation('file') }} <span class="required">*</span></label>
          <input class="field field--full-width" :class="{'is-invalid':$v.document.file.$error}" ref="file" type="file" name="file" id="file">
          <div v-if="$v.document.type_id.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
        </div>
        <div class="form-group">
          <label class="form__label--block form__label">{{ getTranslation('description') }}
          </label>
          <textarea v-model.trim="document.description" class="field  field--full-width  field--short-area" cols="30" rows="10"/>
        </div>
      </form>
    </modal>

    <modal
        :title="this.getTranslation('setCoupon')"
        :show.sync="showCouponModal"
        :centered="false"
        :ok-button-label="okButtonLabel"
        @clickOK="setCoupon"
        @modalCancel="closeCouponModal"
    >
      <ul class="nav nav-pills nav-justified">
        <li class="nav-item">
          <a class="nav-link" :class="{'active':tabs.newCoupon}" href="javascript:void(0)" @click="tabSelect('newCoupon','newCoupon',false)">{{ getTranslation('create') }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" :class="{'active':tabs.coupons}" href="javascript:void(0)" @click="tabSelect('coupons','coupons',false)">{{ getTranslation('coupons') }}</a>
        </li>
      </ul>
      <div v-if="hasPermission('company.item.setCoupon')" class="tab-content mt-3">
        <div :class="{'show active':tabs.newCoupon}" class="tab-pane fade">

          <form method="get" @submit.prevent="setCoupon">

            <div class="form-group">
              <label class="form__label  form__label--block">{{ getTranslation('title') }}</label>
              <input type="text" class="field" v-model.trim="coupon.title" :class="{'is-invalid':$v.coupon.title.$error}"
                     :placeholder="getTranslation('title')">
            </div>

            <div class="form-group">
              <label class="form__label  form__label--block">{{ getTranslation('code') }}</label>
              <input type="text" class="field" v-model.trim="coupon.code"
                     :placeholder="getTranslation('code')" :class="{'is-invalid':$v.coupon.code.$error}">
            </div>

            <div class="form-group mt-3">
              <label class="form__label form__label--block">{{ getTranslation('coupon_description') }}</label>
              <ckeditor :editor="editorOptions.editor" v-model.trim="coupon.description" :config="editorOptions.editorConfig"></ckeditor>
            </div>

          </form>

        </div>
        <div :class="{'show active':tabs.coupons}" class="tab-pane fade">

          <table class="table table-borderless table-hover mt-2">
            <thead class="thead-light">
            <tr>
              <th scope="col" style="width: 3%">#</th>
              <th scope="col" colspan="2" style="width: 50%">
                <form method="get" @submit.prevent="fetchCoupons">
                  <div class="field-advanced">
                    <div class="field-advanced__inner">
                      <div class="field-advanced__field-wrap">
                        <input v-model="couponFilterForm.query" class="field  field--full-width  field--bg-grey  js-borderOnFocus" type="text" :placeholder="getTranslation('search')">
                      </div>

                      <div class="field-advanced__right">
                        <button class="btn btn-primary">
                          <svg class="btn__icon">
                            <use xlink:href="#icon__search"/>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </th>
              <th scope="col" style="width: 5%"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="coupon in coupons" v-bind:key="coupon.id">
              <th scope="row">{{ coupon.id }}</th>
              <td>{{ coupon.code }}</td>
              <td>{{ coupon.title }}</td>
              <td>
                <a v-if="company.status === 'CONFIRMED' && company.coupon_id !== coupon.id" href="javascript:void(0)" @click="setCoupon(coupon.id)" class="btn btn-sm btn-primary">{{ getTranslation('choose') }}</a>
                <a href="javascript:void(0)" @click="removeCoupon" v-if="company.coupon_id === coupon.id && hasPermission('company.item.removeCoupon')" class="btn btn-sm btn-danger">
                  <CIcon name="cil-minus"/>
                  &nbsp
                </a>
              </td>
            </tr>
            </tbody>
          </table>
          <CPagination
              v-if="couponPagination.last_page > 1"
              class="mt-3"
              :active-page.sync="couponPagination.current_page"
              :pages="couponPagination.last_page"
          />

        </div>
      </div>
    </modal>

  </div>
</template>

<script>
import DocumentsComponent from "@/components/shared/DocumentsComponent";
import required from "vuelidate/lib/validators/required";
import companyAction from "@/views/admin/companies/mixins/companyAction";
import ckeditorMixin from "@/components/mixins/Ckeditor";
import companyControllerMixin from "@/views/admin/companies/mixins/CompanyController";
import { contactForm, contactFormValidation, requisiteForm, requisiteFormValidation, serviceForm, serviceFormValidation, serviceOptionsForm, serviceOptionsFormValidation } from "@/views/admin/companies/api";
import phoneMixin from "@/components/mixins/PhoneCode";
import PhoneCode from "@/components/shared/PhoneCode";

export default {
  name: "ViewCompany",
  components: { PhoneCode, DocumentsComponent },
  mixins: [ companyControllerMixin, companyAction, ckeditorMixin, phoneMixin ],
  data() {
    return {
      company: { id: null },
      requisite: { id: null },
      modals: {
        requisite: false,
        service: false,
        contact: false,
        serviceOption: false
      },
      showModal: false,
      showCouponModal: false,
      documents: [],
      services: [],
      coupons: [],
      serviceOptions: [],
      document: {
        company_id: '',
        type_id: '',
        title: '',
        description: '',
        file: ''
      },
      tabs: {
        contact: false,
        requisite: false,
        service: false,
        serviceOption: false,
        document: false,
        newCoupon: false,
        coupons: false,
      },
      couponFilterForm: {
        query: '',
      },
      coupon: {
        title: '',
        code: '',
        coupon_id: '',
        description: '',
      },
      couponPagination: {
        total: 1,
        last_page: 1,
        current_page: 1
      },
      okButtonLabel: '',
      requisiteForm: requisiteForm(),
      serviceForm: serviceForm(),
      serviceOptionsForm: serviceOptionsForm(),
      contactForm: contactForm()
    }
  },
  beforeDestroy() {
    this.$store.dispatch('heading/resetCompanyInfo');
  },
  created() {
    this.tabSelect('contact');
    this.fetchCompany().then(() => {

      this.updateHeadings();

    });
    this.fetchFormOptions();
  },
  watch: {
    'tabs.requisite'(val) {
      if ( val )
        this.fetchRequisite();
    },
    'tabs.document'(val) {
      if ( val )
        this.fetchDocuments();
    },
    'tabs.service'(val) {
      if ( val )
        this.fetchServices();
    },
    'tabs.serviceOption'(val) {
      if ( val )
        this.fetchServiceOptions();

    },
    'tabs.coupons'(val) {
      if ( val ) {
        this.okButtonLabel = '';
        this.fetchCoupons();
      }
    },
    'tabs.newCoupon'(val) {
      if ( val )
        this.okButtonLabel = this.getTranslation('save')
    },
    'couponPagination.page'(val) {
      this.fetchCoupons(val || 1);
    }
  },
  methods: {
    updateContact() {
      this.$v.contactForm.$touch();
      if ( this.$v.contactForm.$invalid )
        return this.snotifyError('wrongFormMsg');

      this.saveContact(this.company.id, this.contactForm)
          .then(() => {
            this.snotifySuccess('saveSuccessMsg');
            this.fetchCompany();
            this.resetContactForm();
          }).catch(() => {
      });
    },

    resetContactForm() {
      this.$v.contactForm.$reset();
      this.contactForm = contactForm();
      this.modals.contact = false;
    },
    openContactForm() {
      this.modals.contact = true;
      this.contactForm = contactForm(this.company.contact);
      this.contactForm.company_id = this.company.id;
    },
    saveServiceOptions() {
      this.$v.serviceOptionsForm.$touch();
      if ( this.$v.serviceOptionsForm.$invalid )
        return this.snotifyError('wrongFormMsg');

      this.addServiceOptions(this.serviceOptionsForm)
          .then(() => {
            this.snotifySuccess('saveSuccessMsg');
            this.fetchServiceOptions();
            this.resetServiceOptionsForm();
          }).catch(() => {
      });
    },

    resetServiceOptionsForm() {
      this.$v.serviceOptionsForm.$reset();
      this.serviceOptionsForm = serviceOptionsForm();
      this.modals.serviceOption = false;
    },
    openServiceOptionsForm() {
      this.modals.serviceOption = true;
      this.serviceOptionsForm.company_id = this.company.id;

      if ( this.serviceOptions.length > 0 ) {
        this.serviceOptionsForm.serviceOptions = this.serviceOptions.map(item => item.id);
      }

    },
    saveServices() {
      this.$v.serviceForm.$touch();
      if ( this.$v.serviceForm.$invalid )
        return this.snotifyError('wrongFormMsg');

      this.addServices(this.serviceForm)
          .then(() => {
            this.snotifySuccess('saveSuccessMsg');
            this.fetchServices();
            this.resetServiceForm();
          }).catch(() => {
      });
    },
    resetServiceForm() {
      this.$v.serviceForm.$reset();
      this.serviceForm = serviceForm();
      this.modals.service = false;
    },
    openServiceForm() {
      this.modals.service = true;
      this.serviceForm.company_id = this.company.id;

      if ( this.services.length > 0 ) {
        this.serviceForm.services = this.services.map(item => item.id);
      }

    },
    saveRequisite() {
      this.$v.requisiteForm.$touch();
      if ( this.$v.requisiteForm.$invalid )
        return this.snotifyError('wrongFormMsg');

      this.requisiteForm.company_id = this.company.id;

      this.addRequisites(this.requisiteForm)
          .then(() => {
            this.snotifySuccess('saveSuccessMsg');
            this.fetchRequisite();
            this.resetRequsiteForm();
          }).catch(() => {
      });

    },
    resetRequsiteForm() {
      this.$v.requisiteForm.$reset();
      this.requisiteForm = requisiteForm();
      this.modals.requisite = false;
    },
    openRequisiteForm() {
      this.modals.requisite = true;

      if ( this.requisite.id ) {
        this.requisiteForm = requisiteForm(this.requisite);
      }

    },
    removeCoupon() {
      this.axios.post(this.$urls.company.removeCoupon.url.replace(':id', this.$route.params.id))
          .then(() => {
            this.snotifySuccess('saveSuccessMsg');
            this.fetchCompany();
          }).catch(() => {
      })
    },
    fetchCoupons(page = 1) {
      let params = this.cleanObject({ ...this.couponFilterForm });
      params.page = page;
      params.type_id = 4;
      this.axios.get(this.$urls.coupon.getList.url, { params: params })
          .then(response => {
            this.coupons = response.data.data.items;
            this.couponPagination = response.data.data.pagination;
          }).catch(() => {
      });
    },
    setCoupon(couponId = false) {

      if ( couponId )
        this.coupon.coupon_id = couponId;

      if ( !this.coupon.coupon_id ) {
        this.$v.coupon.$touch();

        if ( this.$v.coupon.$invalid )
          return this.snotifyError('wrongFormMsg');
      }

      this.axios.post(this.$urls.company.setCoupon.url.replace(':id', this.$route.params.id), this.coupon)
          .then(response => {

            this.snotifySuccess('saveSuccessMsg');
            this.closeCouponModal();
            this.fetchCompany();

          }).catch(() => {
      })
    },
    openCouponModal() {
      this.showCouponModal = true;
      this.tabSelect('newCoupon', 'newCoupon', false);
    },
    closeCouponModal() {
      this.showCouponModal = false;
      this.tabSelect('contact', 'contact', false)
      this.resetCoupon();
    },
    resetCoupon() {
      this.$v.coupon.$reset();
      this.coupon = {
        coupon_id: '',
        title: '',
        code: '',
        description: '',
      };
    },
    confirmCompany() {
      Swal.fire({
        title: this.getTranslation('Confirm company') + ' ?',
        text: this.getTranslation("You won't be able to revert this!"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.getTranslation('OK')
      }).then((result) => {

        if ( result.isConfirmed ) {
          this.axios.post(this.$urls.company.confirm.url.replace(':id', this.$route.params.id))
              .then(response => {
                this.fetchCompany().then(() => {
                  this.updateHeadings();
                })
              }).catch(() => {
          });

        }
      })
    },
    updateHeadings() {
      this.$store.dispatch('heading/setCompanyInfo', {
        status: this.company.status
      });

      let actions = [];

      if ( this.hasPermission('company.profile.confirm') && this.company.status === 'NEW' ) {
        actions.push({
          label: this.getTranslation('companyConfirm'),
          action: this.confirmCompany,
          icon: {
            icon: '#icon__shape-check',
            classes: 'btn__icon--success'
          }
        });
      }

      if ( this.hasPermission('company.item.setCoupon') && this.company.status === 'CONFIRMED' ) {
        actions.push({
          label: this.getTranslation('setCoupon'),
          action: this.openCouponModal,
          icon: {
            icon: '#icon__favorites',
            classes: 'btn__icon--success'
          }
        });
      }

      this.setHeadings({
        title: this.company.title,
        toBack: {
          label: this.getTranslation('toBack', this.getTranslation('companies')),
          route: { name: 'company.getList' }
        },
        actions: actions,
        breadcrumbs: [
          {
            to: { name: 'company.getList' },
            text: this.getTranslation('companies')
          },
          {
            text: this.company.title
          }
        ],
      });
    },
    fetchServices() {
      this.axios.get(this.$urls.company.services.url.replace(':id', this.$route.params.id))
          .then(response => {
            this.services = response.data.data;
          }).catch(() => {
      });
    },
    fetchServiceOptions() {
      this.axios.get(this.$urls.company.serviceOptions.url.replace(':id', this.$route.params.id))
          .then(response => {
            this.serviceOptions = response.data.data;
          }).catch(() => {
      });
    },
    reset() {
      this.$v.document.$reset();

      this.document = {
        company_id: '',
        type_id: '',
        title: '',
        description: '',
        file: ''
      };
      this.showModal = false;
    },
    openDocumentModal() {
      this.fetchFormOptions().then(() => {
        this.showModal = true;
      })
    },
    uploadDocument() {

      this.document.company_id = this.$route.params.id;

      let file = this.$refs.file.files[0];

      if ( !file )
        return this.snotifyError('chooseFile');

      let formData = new FormData();
      formData.append('file', file);

      this.document.file = file;

      this.$v.document.$touch();

      if ( this.$v.document.$invalid )
        return this.snotifyError('wrongFormMsg');

      for ( let prop in this.document ) {
        formData.append(prop, this.document[prop]);
      }


      this.axios.post(this.$urls.portal.addDocument.url, formData)
          .then(response => {
            this.snotifySuccess('saveSuccessMsg');
            this.reset();
            this.fetchDocuments();
          }).catch(() => {
      });

    },
    fetchCompany() {
      return this.axios.get(this.$urls.company.company.url.replace(':id', this.$route.params.id))
          .then(response => {
            this.company = response.data.data;
          }).catch(() => {
          });
    },
    fetchRequisite() {
      this.axios.get(this.$urls.company.requisites.url.replace(':id', this.$route.params.id))
          .then(response => {
            if ( response.data.data.requisite )
              this.requisite = response.data.data.requisite;
          }).catch(() => {
      });
    },
    fetchDocuments() {
      this.axios.get(this.$urls.company.documents.url.replace(':id', this.$route.params.id))
          .then(response => {
            this.documents = response.data.data;
          }).catch(() => {
      });
    }
  },
  validations: {
    requisiteForm: requisiteFormValidation(),
    serviceOptionsForm: serviceOptionsFormValidation(),
    serviceForm: serviceFormValidation(),
    contactForm: contactFormValidation(),
    document: {
      type_id: {
        required
      },
      company_id: {
        required
      },
      file: {
        required
      }
    },
    coupon: {
      title: {
        required
      },
      code: {
        required
      },

    }
  }
}
</script>

<style scoped>

</style>